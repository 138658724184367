<template>
  <div>
    <table class="table" v-if="done">
      <thead>
        <tr>
          <th>id</th>
          <th>Call #</th>
          <th>Old #</th>

          <th>Title</th>
          <th>Author</th>
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(book,i) in books" v-bind:key="book.id" :class="book.attributes.status_id === 3 ? 'missing' : ''">
          <td>{{book.id}}</td>
          <td :class="book.id > 1861 ? 'new' : ''">{{book.attributes.call_number }}</td>
          <td :style="'background-color: ' + sections[book.attributes.section_id].attributes.colour">
            {{ sections[book.attributes.section_id].attributes.name }}<br />
              {{ book.attributes.catno }}
          </td>
          <td class="book_title">{{book.attributes.title }}</td>
          <td class="book_author">{{book.attributes.author }}</td>
          <td>{{ statuses[book.attributes.status_id] }}</td>
          <td><button v-if="book.attributes.status_id === 1" class="is-danger button is-size-7 is-small"  @click="toggleStatus(book.id, 3)">
            Mark missing</button>
            <button v-if="book.attributes.status_id === 3" class="is-primary button is-size-7 is-small" @click="toggleStatus(book.id, 1)">Mark as found</button>
            <router-link class="button" target="_blank" :to="{name: 'EditBook', params: {id: book.id }}">Edit book</router-link>
            <button  class="button is-small is-secondary" @click="cleanPartnership(i)">Clean patnership</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </div>

</template>
<script>
import normalize from 'json-api-normalizer'
export default {
  data () {
    return {
      done: false,
      fetching: true,
      paginationMeta: null,
      totalPages: null,
      books: null,
      restrictSection: null,
      statuses: {
        1: 'On shelf',
        2: 'Borrowed',
        3: 'Missing'
      }
    }
  },
  methods : {
    toggleStatus(bookId, status) {
      let url = '/v1/books/' + bookId
      this.axios.put(url, { status_id: status })
        .then((response) => {
          this.books.splice(this.books.findIndex(p => p.id === bookId), 1, response.data.data)
        })
    },
    cleanPartnership(bookIndex) {
      this.updating = true
      let url = '/v1/housekeeping/' + this.books[bookIndex].id + '/clean_commas'
      this.axios.get(url)
        .then((response) => {
          if (response.status === 200) {
            this.books.splice(bookIndex, 1, response.data.data)
          }
          this.updating = false
        })
    }
  },
  mounted () {
    this.axios.get('/v1/sections')
      .then((response) => {
        this.sections = normalize(response.data).section
      })
    this.axios.get("/v1/housekeeping/unclassified")
      .then((response) => {
        this.books = response.data.data
        this.done = true
      })
  }
}
</script>
